<template>
  <div class="payment-methods-list">
    <payment-methods-list-item
      class="list-item"
      v-for="(paymentMethod, index) in paymentMethods"
      :key="index"
      :default="index === 0 ? true : false"
      payment-method-type="saved-card"
      :payment-method="paymentMethod"
      :change-event="changeEvent"
      @on-item-select="onItemChange"
    />

    <!-- Sezzle -->
    <payment-methods-list-item
      v-if="showSezzle"
      class="list-item"
      payment-method-type="sezzle"
      :change-event="changeEvent"
      @on-item-select="onItemChange"
    />
    <!-- New payment method -->
    <payment-methods-list-item
      :default="paymentMethods.length === 0"
      payment-method-type="new-card"
      :change-event="changeEvent"
      @on-item-select="onItemChange"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "payment-methods-list-item": () => import("./PaymentMethodsListItem.vue"),
  },
  data: () => ({
    changeEvent: false,
  }),
  computed: {
    ...mapGetters([
      "checkoutRegion",
      "paymentMethods",
      "orderSpartanPlusTicket",
    ]),
    showSezzle() {
      // Hide Sezzle if cart contains Spartan+ ticket
      if (this.orderSpartanPlusTicket) return;
      return this.checkoutRegion === "US";
    },
  },
  methods: {
    // ...mapActions(["getStripePaymentMethods"]),
    onItemChange(paymentMethod) {
      this.changeEvent = !this.changeEvent;
      this.$emit("on-payment-method-change", paymentMethod);
    },
  },
  async mounted() {
    // console.log(this.paymentMethods);
  },
};
</script>
<style lang="scss" scoped>
.payment-methods-list {
  .list-item {
    border-bottom: 1px solid #cfcfcf;
  }
}
</style>